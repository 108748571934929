import React, { useState, useEffect } from 'react';
import FilterResults from 'react-filter-search';
import ReactPaginate from 'react-paginate';
import { faDiceD6 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, TreeView, TreeItem } from "@material-ui/core";
import unflatten from 'unflatten';
import TreeMenu, { defaultChildren, ItemComponent } from 'react-simple-tree-menu';
import { navigate } from 'gatsby';


//////////
//  Data Functions
//////////

function arrayListToNodes(arrayList, referenceDict) {
  // takes an array of array and return the
  // tree format.
  referenceDict = referenceDict || {}; //

  // 1. make it a dict-of-dict for uniqueness
  var heirarchyDict = {}

  arrayList.forEach((grouplist) => applyHeirarchy(heirarchyDict, grouplist, ""));
  // heirarchyDict is now e.g. { c360_dataset: { user: { foo: {}, bar: {} }, common: {} } }
  // with each obj having at least one `__dkey`

  // 2. from dict-of-dict, transform to node tree
  let heirarchyTree = heirarchyDictToNodes(heirarchyDict, referenceDict);

  return heirarchyTree;
}

function applyHeirarchy(obj, list, parents) {
  if ( obj[list[0]] == null ) { obj[list[0]] = {__dkey: parents + "/" + list[0]} };

  if ( list.length > 1 ) {
      // multiple group in list, apply heirarchy for children
      applyHeirarchy(obj[list[0]], list.slice(1), parents + "/" + list[0])
  }
  // function operates directly to obj so it does not return
}

function heirarchyDictToNodes(obj, referenceDict) {
  var nodeList = []
  var path
  Object.entries(obj).forEach(
      ([key, value], i) => {
          if (key == "__dkey") return;
          path = obj.__dkey
          if (path) {
            if (path.slice(1) == "/") {
              path.substring(1)
            }
          }
          nodeList.push({
              key: key,
              label: key,
              // having more than 1 keys means dataset (all obj has __dkey)
              datasetinfo: Object.keys(value).length > 1 ? referenceDict[value.__dkey] : null,
              tableinfo: Object.keys(value).length <= 1 ? referenceDict[value.__dkey] : null,
              nodes: heirarchyDictToNodes(value, referenceDict),
              dataset_id: referenceDict[value.__dkey.dataset_id],
          })
      }
  )
  nodeList.sort((a, b) => {
    if (a.key < b.key) return -1;
    if (a.key > b.key) return 1;
    return 0;
  });

  return nodeList;
}


function getTreeData(data) {
  if (data == null) {
    return []
  }
  let datasetMap = data.reduce((map, obj) => {
    let key = "/" + obj.groups.concat([obj.datapackage.name]).join("/");
    map[key] = {
      // TODO: include more datapackage field
      name: obj.datapackage.name,
      title: obj.datapackage.title,
      description: obj.datapackage.description,
      dataset_id: obj.datapackage.dataset_id,
      originalObj: obj,
    };
    return map;
  }, {})

  let heirarchyList = []

  data.forEach((dataset) => {
    if (dataset.datapackage == null) return;
    // if (dataset.datapackage.resources == null) return;
    let key_prefix = "/" + dataset.groups.concat([dataset.datapackage.name]).join("/");
    dataset.datapackage.resources.forEach((table) => {
      var key;
      if (table.path.length > 0) {
        key = key_prefix + "/" + table.path[0].split("/")[0] + "/" + table.name;
        heirarchyList.push(
          dataset.groups.concat([dataset.datapackage.name, table.path[0].split("/")[0], table.name])
        )
      } else {
        key = key_prefix + "/<others>/" + table.name;
        heirarchyList.push(
          dataset.groups.concat([dataset.datapackage.name, "<others>", table.name])
        )
      }
      datasetMap[key] = table;
    })
  })

  return arrayListToNodes(heirarchyList, datasetMap);
}

//////////
//  Data Functions - End
//////////


function EmptyPage(props) {
  return (
    <div className="has-text-centered">
      <div className="is-vcentered" style={{ margin: 'auto' }}>
        <FontAwesomeIcon
          icon={faDiceD6}
          style={{ fontSize: '5em', color: 'lightgrey', margin: '20px' }}
        />
        <p>
          Select an item to begin.
        </p>
      </div>
    </div>
  )
}

function GalleryMenubarLayoutTreeReg(props) {
  const { data, selectedItem, setSelectedItem, CatalogItemRender, filterValue } = props;
  const { sharedState, setSharedState, identityKeyFunc, initialOpenNodes } = props;
  const treeData = getTreeData(data);

  const DEFAULT_PADDING = 16;
  const ICON_SIZE = 8;
  const LEVEL_SPACE = 20;

  console.log("initialOpenNodes", initialOpenNodes)

  return (
    <TreeMenu
      data={treeData}
      initialOpenNodes={initialOpenNodes}
    >
      {
        ({ search, items }) => {
          return (
            <List>
              {
                items.map(({key, datasetinfo, level, focused, ...props}) => {
                  if (datasetinfo) {
                    return (
                      <div
                        style={{
                          paddingLeft: DEFAULT_PADDING + ICON_SIZE + level * LEVEL_SPACE,
                          cursor: "pointer",
                          fontWeight: `${ level == 0 ? "bold" : ""}`,
                          backgroundColor: focused ? 'lightBlue' : 'none'

                        }}
                      >
                        <ListItem
                          onClick={() => {
                            setSelectedItem(datasetinfo.originalObj);
                            if (identityKeyFunc) {
                              const url = identityKeyFunc(datasetinfo.originalObj);
                              // navigate(url);
                            }
                          }}
                        >
                          <CatalogItemRender
                            datapackage={datasetinfo.originalObj.datapackage}
                            selecteditem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            sharedState={sharedState}
                            setSharedState={setSharedState}
                            filterValue={filterValue}
                          />
                        </ListItem>
                      </div>
                    )
                  } else {
                    return (
                      <ItemComponent key={key} level={level} focused={focused} {...props} />
                    )
                  }
                })
              }
            </List>
          )

        }
      }
    </TreeMenu>
  )
}


export default function GalleryMenubarLayout({
  data,
  filterValue,
  CatalogItemRender,
  CatalogPageRender,
  catalogEmptyPageRender,
  CatalogCreateRender,
  selectedItem,
  setSelectedItem,
  setIsCreating,
  isCreating,
  sharedState,
  setSharedState,
  identityKeyFunc,
  initialOpenNodes,
}) {
  /*
    `data` - can either be an Array, which will render the list version
             of this layout. Alternatively, return an object of
             { groups: array } to render a herarchy tree.
  */

  const containerStyle = {maxHeight: '70vh', overflowY: 'auto'};

  var rightSideComponent = "";
  var FinalEmptyPageRender = catalogEmptyPageRender || EmptyPage;

  if (selectedItem) {
    rightSideComponent = (
      <CatalogPageRender
        {...selectedItem}
        sharedState={sharedState}
        setSharedState={setSharedState}
      />
    )
  } else {
    rightSideComponent = (
      <FinalEmptyPageRender data={data}/>
    )
  }

  // isCreating will override rightSideComponent
  if (isCreating) {
    rightSideComponent = (
      <CatalogCreateRender
        {...selectedItem}
        setIsCreating={setIsCreating}
        sharedState={sharedState}
        setSharedState={setSharedState}
      />
    )
  }

  return (
    <div className='columns'>
      <div className='column is-3' style={containerStyle}>
        <GalleryMenubarLayoutTreeReg
          data={data}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          CatalogItemRender={CatalogItemRender}
          sharedState={sharedState}
          setSharedState={setSharedState}
          filterValue={filterValue}
          identityKeyFunc={identityKeyFunc}
          initialOpenNodes={initialOpenNodes}
        />
      </div>
      <div className='column is-9' style={containerStyle}>
        { rightSideComponent }
      </div>
    </div>
  )
}
