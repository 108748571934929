import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React from 'react';
import { Link } from 'gatsby';
import preset_icons from '@tra-sg/gatsby-theme-c360-portal/src/icons/presets';
import { navigate } from "@reach/router";


class ModelMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modelId: props.modelId,
      chartType: props.chartType,
      xValue: props.xValue,
      yValue: props.yValue,
      metric: props.metric,
      colorWheel: props.colorWheel,
      disableColorOffset: props.disableColorOffset,
      isLoading: true,
      data: null,
      error: null
    };
  }

  componentDidMount() {
    this.fetchData()
  }


  fetchData() {
    const callApiUrl = `model/${this.state.modelId}/metrics`; // TODO: actual implementation with date_picker
    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.metrics;
        if (loadedResult == null) throw Error('Invalid model metrics info received.');
        if (loadedResult === []) {
          // no permission to data
          this.setState({
            error: null,
            data: [],
          });
        } else {
          this.setState({
            error: null,
            data: loadedResult,
            isLoading: false
          });
        }
      },
      (error) => this.setState({ error, isLoading: false }),
    );
  }


  render() {
    let { chartType, xValue, yValue, metric, colorWheel, disableColorOffset, data, error, isLoading } = this.state

    console.log("loading status", data, error, isLoading)

    let metricData = []

    if (error) {
      return (
        <div className="columns is-centered">
          Invalid model metrics info received.
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    var usedData;
    if (data == null) {
      usedData = [];
    } else {
      usedData = data;
    }

    if ((usedData || []).length > 0) {
      usedData.forEach(indMetric => {
        if (indMetric.name == metric) {
          metricData = indMetric
        }
      })
    }

    console.log('ModelMetric metricData', metricData)

    if (chartType) {
      return (
        <div>
          <QuickLook
            subtitle={metricData.subtitle}
            chartType={chartType}
            data={metricData.data}
            X={xValue}
            Y={yValue}
            colorWheel={colorWheel}
            disableColorOffset={disableColorOffset}
          />
          {
            metricData.context ? (<div className="card">
              <div className="card-content">
                {metricData.context}
              </div>
            </div>) : ""
          }
        </div>
      )
    } else {
      return (
        metricData.data
      )
    }
  }
}

export default ModelMetrics;
