import React from 'react';
import ExperimentView from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/ExperimentView'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";

export default function ExperimentViewPage({}) {
  return (
    <PageWrapper
      activeTab="labs"
      metaTitle="Labs | c360"
    >
      <Location>
        {({ location }) => (<ExperimentView />)}
      </Location>
    </PageWrapper>
  )
}
