import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import ReactMarkdown from 'react-markdown';
import React, { useState } from 'react';
import { navigate } from "gatsby";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDEditor from '@uiw/react-md-editor';
import NewNotebook from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/NewNotebook';


function TableOverviewPermission(props) {
  // mock first
  const { userList, permission } = props;

  return (
    <div>
      <div className="level pl-4" style={{marginBottom: '0.1rem'}}>
        <div className="level-left">
          <div className="level-item">
            <span className="is-italic">Editor:</span>
          </div>
          <div className="level-item">
            <span className="tag is-primary is-light">Analysts</span>
          </div>
          <div className="level-item">
            <span className="tag is-primary is-light">Data Engineers</span>
          </div>
        </div>

      </div>
      <div className="level pl-4" style={{marginBottom: '1rem'}}>
        <div className="level-left">
          <div className="level-item">
            <span className="is-italic">Viewer:</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">Auditors</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">TRA</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">BSI</span>
          </div>
        </div>

      </div>
    </div>
  )
}


class TableOverview extends React.Component {
  constructor(props) {
    super(props);
    // if `props.data` is given, use the given data instead of pulling it from API

    this.state = {
      data: props.data,
      isEditing: props.isEditing,
      tableId: props.tableId,
    };
  }


  // fetchData() {
  //   let payload = {
  //     table_name: this.state.tableId,
  //     dataset_name: this.props.dataset_id || this.getDatasetIdFromURL(),
  //     zone: this.props.zone || this.getZoneIdFromURL(),
  //     groups: this.props.groups || this.getGroupIdFromURL()
  //   }
  //   const callApiUrlForFetch = `dataset/table/get?table_name=${payload.table_name}&dataset_name=${payload.dataset_name}&zone=${payload.zone}&groups=${payload.groups}`
  //   this.setState({ isLoading: true });

  //   const callApiUrl = `dataset/table/edit`;
  //   callApi(
  //     callApiUrlForFetch,
  //     (result) => {
  //       const data = result;
  //       if (data == null) throw Error('Invalid table infor received.');
  //       if (data === []) {
  //         // no permission to data
  //         this.setState({
  //           error: null,
  //         });
  //       } else {
  //         this.setState({
  //           data: data,
  //           isLoading: false,
  //           tableOverviewDescription: data.description || null,
  //         })
  //       }
  //     },
  //     (error) => this.setState({ error }),
  //   );
  // }


  jsonifyWithEscape(string) {
    var myJSONString = JSON.stringify(string);
    var myEscapedJSONString = myJSONString.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    return myEscapedJSONString
  }

  handleChange = value => {
    this.setState({
      tableOverviewDescription: value
    });
  };


  async editTableDescription(tableId) {
    const { isEditing, tableOverviewDescription } = this.state
    if (isEditing) {
      var r = window.confirm(`Update table ${tableId} description?`);
      if (r == true) {
        this.setState({
          updatingStatus: true
        })

        let payload = {
          table_name: tableId,
          dataset_name: this.getDatasetIdFromURL(),
          zone: this.getZoneIdFromURL(),
          description: tableOverviewDescription,
          groups: this.getGroupIdFromURL()
        }

        const callApiUrl = `dataset/table/edit`;
        await callApi(
          callApiUrl,
          (result) => {
            const data = result.event;
            if (data == null) throw Error('Performance is not succeeded.');
            if (data === []) {
              // no permission to data
              this.setState({
                error: null,
              });
            } else {
              this.setState({
                data: data
              })
            }
          },
          (error) => this.setState({ error }),
          {
            method: 'POST',
            body: this.jsonifyWithEscape(payload),
            headers: { 'Content-Type': 'application/json' },
          }
        );
        // if (this.state.data.includes("Successfully")) {
        if (this.state.data.includes("Successfully")) {
          this.setState({
            updatingStatus: false,
            isEditing: false,
          })
          window.alert(`Table ${tableId} updated!`);
          location.reload()
        } else {
          window.alert(`Unauthorized`)
          this.setState({
            updatingStatus: false,
            isEditing: false
          })
        }
      } else {
        window.alert("Nothing changes!")
        this.setState({
          isEditing: false
        })
      }
    } else {
      this.setState({
        isEditing: true
      })
    }
  }

  disabledIfNotCommonDataset() {
    let url = window.location.href
    if (url.includes("dataset/common")) {
      return false
    } else {
      return true
    }
  }

  addDefaultTabToURL(url) {
    if (url) {
      if (url.substring(url.length-1) == "/") {
        url = `${url.substring(0, url.length-1)}/?tab=overview`;
      }
    }
    return url;
  }

  getDatasetIdFromURL() {
    let url = window.location.href
    url = this.addDefaultTabToURL(url)
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 3]
  }

  getZoneIdFromURL() {
    let url = window.location.href
    url = this.addDefaultTabToURL(url)
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 2]
  }

  getGroupIdFromURL() {
    let url = window.location.href
    url = this.addDefaultTabToURL(url)
    if (url.includes("/?tab=")) {
      url = url.split("/?tab")[0]
    }
    return url.split("/")[url.split("/").length - 4]
  }


  parseDate(dateString) {
    if (dateString) {
      let str_date = `${dateString.substring(0,4)}-${dateString.substring(4,6)}-${dateString.substring(6,8)} ${dateString.substring(9,11)}:${dateString.substring(11,13)}:${dateString.substring(13,15)} UTC`
      return (new Date(Date.parse(str_date))).toLocaleString();
    } else {
      return `Not available`
    }
  }


  render() {
    const { error, isEditing, tableId, data, tableOverviewDescription } = this.state;

    if (!this.disabledIfNotCommonDataset()) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            Currently Table Overview is not available for Common datasets. Please proceed to Schema tab.
          </div>
        </div>
      )
    }

    if (error) {
      if (error.message.includes('404')) {
        return "Table Overview is not available."
      }
      return (
        error.message
      );
    }

    if (data) {
      let user_list = ""
      if (data.permission_users) {
        data.permission_users.map(user => {
          if (user_list != "") {
            user_list = user_list + `, ${user}`
          } else {
            user_list = user
          }
        })
      }
      return (
        <div className="columns half-vh is-mobile">
          <div className="column is-full">
            <div className="container">
              <p className="is-size-12"><strong>Description: </strong>
                <button className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`} disabled={!this.disabledIfNotCommonDataset()} aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => this.editTableDescription(tableId)} style={{fontSize: "10px"}}>
                  <FontAwesomeIcon icon={ isEditing ? faSave : faPencilAlt } />
                </button>
              </p>
              <div className="description" style={{ border: "0.25px solid", padding: "25px 15px 25px 15px", borderRadius: "5px", borderColor: "lightGrey", backgroundColor: "#f5f7f7"}}>
                { isEditing ? (
                  <div id="table_readme">
                    <MDEditor
                      value={tableOverviewDescription}
                      onChange={this.handleChange}
                    />
                  </div>
                ) : (
                  <div id="table_readme" style={tableOverviewDescription ? { color: "black" } : { color: "grey" }}>
                    <MDEditor.Markdown source={tableOverviewDescription || null} />
                  </div>
                )}
              </div>
            </div>
            <br/>
            <br/>
            <p className="is-size-12" style={{marginBottom: '0.25rem'}}><strong>Permissions:</strong></p>
            <TableOverviewPermission userList={user_list} permission={data.permission} />
            <p className="is-size-12"><strong>Created:</strong>  <text>{this.parseDate(data.created)}</text></p>
            <p className="is-size-12"><strong>Last Updated:</strong>  <text>{this.parseDate(data.lastModified)}</text></p>
            <br/>
            {/* <NewNotebook table_id={tableId} /> */}
            {/* <a className="button">Build Model</a> */}
          </div>
        </div>
      )
    } else {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            Table Overview is not available.
          </div>
        </div>
      )
    }
  }
}

export default TableOverview;
