import { faFlask, faChartLine, faSearchPlus, faClipboardList, faShoppingCart, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React from 'react';
import DatePicker from 'react-date-picker';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import preset_icons from '@tra-sg/gatsby-theme-c360-portal/src/icons/presets';
import { navigate } from "gatsby";
import ModelMetrics from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/ModelMetrics';
import ModelSandbox from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/ModelSandbox';
import KPIForecastDemo from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/KPIForecastDemo'
import MDEditor from '@uiw/react-md-editor';


class ModelBrowser extends React.Component {
  constructor(props) {
    /*
      Children of this component must have a `modelTab` props that corresponds
      to which tab they are renderred in (e.g. "overview").
    */
    super(props);

    this.state = {
      modelId: props.modelId,
      error: null,
      isLoading: true,
      data: null,
      description: props.description,
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  dropDownClick = () => {
    let dropdown = document.getElementById("add-dropdown")
    if (dropdown) {
      if (dropdown.className.includes("is-active")) {
        dropdown.classList.remove("is-active")
      } else {
        dropdown.classList.add("is-active")
      }
    }
  }

  fetchData() {
    const callApiUrl = 'models'; // TODO: actual implementation with date_picker
    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.models;
        if (loadedResult == null) throw Error('Invalid models info received.');
        if (loadedResult === []) {
          // no permission to data
          this.setState({
            error: null,
            data: [],
            // modelId: null,
          });
        } else {
          var hasModel = false;
          loadedResult.forEach(model => {
            console.log("Looking for", this.props.modelId, model.name)
            if (model.name == this.props.modelId) {
              hasModel = true
            }
          })
          this.setState({
            error: hasModel ? null : {message: "Model data not found"},
            data: loadedResult,
            // modelId: loadedResult[0].name
          });
          this.setState({
            isLoading: false,
          });
        }
      },
      (error) => this.setState({ error, isLoading: false }),
    );
  }

  renderRight() {
    return (
      <div className="rows">
        <br></br>
        <div className="row is-full">
          {this.renderModelVersion()}
        </div>
        <div className="row is-full">
          {this.renderModelTabs()}
        </div>
      </div>

    );
  }

  renderError() {
    const messageClass = 'is-danger';
    const errorReason = 'It seems like something went wrong with the Models Browser.';

    const { error } = this.state;

    return (
      <div className="columns is-full">
        <article className={`message ${messageClass}`}>
          <div className="message-body">
            { errorReason }
            <br />
            <i>
              {' '}
              Error:
              { error.message }
              {' '}

            </i>
          </div>
        </article>
      </div>
    );
  }

  renderModelVersion() {
    let { data, modelId } = this.state
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })

    let versions = modelData.versions
    if ((versions || []).length > 0) {
      let versionDiv = []
      versions.forEach(version => {
        versionDiv.push(<Link to="#" className="dropdown-item" onClick={this.dropDownClick}>{version.name}</Link>)
      })
      return (
        <div className="columns">
          <div className="column is-four-fifths">
          </div>
          <div className="column is-one-fifth">
            <div className="dropdown is-right" id="add-dropdown">
              <div style={{paddingTop: "10px", paddingRight: "5px"}}>Model Version: </div>
              <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={this.dropDownClick}>
                { versions[0].name }
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  { versionDiv }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

  }

  renderModelHeader() {
    const { datePicker, modelId } = this.state;
    return (
      <div className="columns">
        <div className="column is-four-fifths">
          <h4 className="title">{modelId}</h4>
        </div>
        <div className="column is-one-fifth">
          <div className="">
            <DatePicker
              onChange={this.onChange}
              value={datePicker}
              clearIcon={null}
              maxDate={new Date()}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTabChildren(tabkey) {

    let children = React.Children.toArray(this.props.children)
    // return children.filter(
    //   (node) => {
    //     node.props.modelTab == tabkey
    //   }
    // )
    return children;
  }

  navigateTab(tabIndex, tabDict) {
    let url = window.location.href.split("?")[0]
    let tab = Object.keys(tabDict).find(key => tabDict[key] === tabIndex)
    // navigate(`${url}?tab=${tab}`)
  }

  renderModelTabs() {
    let { modelId } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    let myParam = urlParams.get('tab');
    if (myParam) {
      myParam = myParam.toLowerCase()
    }
    let tabDict = {
      "overview": 0,
      "performance": 1,
      "evaluationmetrics": 2,
      "features": 3,
      "versions": 4,
      "proofofconcept": 5
    }
    let currentTab = 0
    if (!tabDict[myParam]) {
      currentTab = 0
    } else {
      currentTab = tabDict[myParam]
    }
    return (
      <Tabs defaultIndex={currentTab} onSelect={index => this.navigateTab(index, tabDict)}>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Performance</Tab>
          {/* <Tab>Evaluation Metrics</Tab>
          <Tab>Feature Importance</Tab>
          <Tab>Versions Comparison</Tab> */}
          <Tab>Proof Of Concept</Tab>
        </TabList>

        <TabPanel>
          { this.renderModelOverview() }
        </TabPanel>
        <TabPanel>
          { this.renderModelPerformance() }
        </TabPanel>
        {/* <TabPanel>
          { this.renderModelEvaluationMetrics() }
        </TabPanel>
        <TabPanel>
          { this.renderModelFeatureImportance() }
        </TabPanel>
        <TabPanel>
          { this.renderVersionComparison() }
        </TabPanel> */}
        <TabPanel>
          { this.renderProofOfConcept() }
        </TabPanel>
      </Tabs>
    );
  }


  renderModelOverview() {
    let { data, modelId, description } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })
    let datasetInfoDiv = []
    let versionsDiv = []
    let timeline = modelData.timeline
    modelData.datasets.forEach(dataset => {
      datasetInfoDiv.push(
        <tr>
          <td><Link to={`/datasets/${dataset.name}/${dataset.table}`}> {dataset.name}.{dataset.table} </Link></td>
          <td>{dataset.lastUpdated}</td>
        </tr>
      )
    })
    modelData.versions.forEach(version => {
      versionsDiv.push(
        <tr>
          <td>{version.name}</td>
          <td>{version.lastTrain}</td>
          <td>{version.lastDeploy}</td>
        </tr>
      )
    })
    console.log("description", modelData.description)
    return (
      <div className="columns is-mobile">
        <div className="column is-two-thirds container">
          {/* <span className="title is-6"> About {modelData.name}</span> */}
          <div className="" key="1">
            <MDEditor.Markdown source={modelData.description} />
          </div>
          {this.renderTabChildren("overview")}
          <br></br>
          <span className="title is-6"> Datasets</span>
          <div className="has-text-centered" key="1">
          <table>
            <tr>
              <th>Datasets</th>
              <th>Last Updated</th>
            </tr>
            { datasetInfoDiv }
            </table>
          </div>
          <br></br>
          <span className="title is-6"> Versions</span>
          <div className="has-text-right" key="1">
            <table>
              <tr>
                <th>Name</th>
                <th>Last Training Time</th>
                <th>Last Deploy Time</th>
              </tr>
              { versionsDiv }
            </table>
          </div>
        </div>
        <div className="column is-one-third container">
          {/* { this.renderModelOverviewLatestPerformance() } */}
          <div className="panel">
              <div className="panel-heading">
                Timeline
              </div>
            { timeline.map((v) => this.renderTimelineItem(v, false)) }
              <div className="panel-block">
              <button className="button is-outlined is-fullwidth">
                See all
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderModelOverviewLatestPerformance() {
    let { latestPerformanceConfig, modelId } = this.props;

    if (!latestPerformanceConfig) return;

    let { chartType, X, Y, metric } = latestPerformanceConfig

    // TODO: derive data from API
    return (
      <div className="panel">
        <div className="panel-heading">
          Latest Performance
        </div>
        <div className="pannel-block">
          <ModelMetrics modelId={modelId} chartType={'bar'} xValue={'type'} yValue={['count']} metric={'customer_purchase'}/>
        </div>
      </div>
    )
  }

  renderModelSandbox(modelId, exec_metadata) {
    console.log("exec metadata", exec_metadata)
    console.log("exec metadata MTYPE", exec_metadata.model_type)
    return (
      <ModelSandbox
        modelId={modelId}
        model_type={exec_metadata.model_type || "CLASSIFICATION"}
        // columns={["Age", "Gender", "Siblings/Spouse Aboard", "Parents/Children Aboard", "Passenger Class", "Fare"]}
        columns={exec_metadata.columns || ["Age", "Gender"]}
      />
    )
  }

  renderModelPerformance() {
    const { modelId, data } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })

    console.log("modelData");
    console.log(modelData);

    return (
      <div className="rows">
        {/* {this.renderTabChildren("performance")} */}
        {/* <KPIForecastDemo /> */}
        <h3>Carbon Prediction (Test Period)</h3>
        <img
          src='https://user-images.githubusercontent.com/12974269/171585132-270f9253-073f-463b-93a0-d772d580ad1f.png'
          width={800} height={600}
        />
        <br/><br/><br/>
        <div>
            {modelData.exec_metadata ? this.renderModelSandbox(modelId, modelData.exec_metadata) : ""}
        </div>
        <br/>
        <div className="row is-full">
          <div className="subtitle">Performance History</div>
          <hr/>
          <div className="columns">
            <div className="column is-half">
              <span className="title is-6">Past Model Accuracy</span>
              <div className="has-text-centered" key="1">
                  <QuickLook
                    targetUrl="campaign/kpi-performance-monitor"
                    chartType="bar"
                    modelMetric="gsv"
                    data={modelData.gsv_model_comparison.data}
                    X="date"
                    Y={["baseline", `${modelData.name}`]}
                    chartHeight={285}
                  />
              </div>
            </div>

          </div>
        </div>

      </div>
    )
  }

  renderModelEvaluationMetrics() {
    const { modelId, data } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })
    let evaluationDiv = []
    modelData.evaluation.forEach(evaluation => {
      evaluationDiv.push(
        <tr>
          <td>{evaluation.name}</td>
          <td>{evaluation.value}</td>
        </tr>
      )
    })
    return (
      <div className="columns half-vh">
        <div className="column is-half container">
          <span className="title is-6"> Confusion Matrix</span>
          <div className="has-text-centered" key="1">
          <table>
            <tr>
            <th>n=85000</th>
            <th>Predicted: No</th>
            <th>Predicted: Yes</th>
            </tr>
            <tr>
            <td><b>Actual: No</b></td>
            <td>10000</td>
            <td>2000</td>
            </tr>
            <tr>
            <td><b>Actual: Yes</b></td>
            <td>3000</td>
            <td>70000</td>
            </tr>
            </table>
          </div>
          <br></br>
          <br></br>
          <span className="title is-6"> Others</span>
          <div className="has-text-centered" key="1">
          <table>
            <tr>
              <th>Stats</th>
              <th>Value</th>
            </tr>
            { evaluationDiv }
            </table>
          </div>
        </div>
        <div className="column is-half container">
          <span className="title is-6"> Accuracy</span>
          <div className="has-text-centered" key="1">
              <QuickLook
                targetUrl="campaign/kpi-performance-monitor"
                chartType="modelStats"
                data={modelData.accuracy_version_comparison.data}
                X="date"
                Y={["v2.0", "v1.0"]}
              />
          </div>
        </div>
      </div>
    );
  }

  renderModelFeatureImportance() {
    const { modelId, data } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })
    let featureDiv = []
    modelData.features.forEach(feature => {
      featureDiv.push(
        <tr>
          <td>{feature.name}</td>
          <td>{feature.importance}</td>
          <td>{feature.averageValue}</td>
        </tr>
      )
    })
    return (
      <div className="columns half-vh">
        <div className="column is-half container">
          <span className="title is-6"> Timeline</span>
          <div className="has-text-centered" key="1">
            <QuickLook
              chartType="line"
              data={modelData.feature_importance.data}
              X="date"
              Y={["avg_days_between_purchases", "avg_early_adopter_score", "days_since_last_purchase", "time_with_acme"]}
              chartHeight={320}
            />
          </div>
        </div>
        <div className="column is-half container">
          <span className="title is-6"> Features</span>
          <div className="has-text-centered" key="1">
          <table>
            <tr>
              <th>Feature</th>
              <th>Importance</th>
              <th>Average Value</th>
            </tr>
            { featureDiv }
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderVersionComparison() {
    const { modelId, data } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })

    return (
      <div className="columns half-vh">
        <div className="column is-half container">
          <span className="title is-6"> GSV Contribution</span>
          <div className="has-text-centered" key="1">
              <QuickLook
                targetUrl="campaign/kpi-performance-monitor"
                chartType="modelStats"
                data={modelData.gsv_version_comparison.data}
                X="date"
                Y={["v2.0", "v1.0"]}
              />
          </div>
        </div>
        <div className="column is-half container">
          <span className="title is-6"> Accuracy</span>
          <div className="has-text-centered" key="1">
              <QuickLook
                targetUrl="campaign/kpi-performance-monitor"
                chartType="modelStats"
                data={modelData.accuracy_version_comparison.data}
                X="date"
                Y={["v2.0", "v1.0"]}
              />
          </div>
        </div>
      </div>
    );
  }

  renderProofOfConcept() {
    const { modelId, data } = this.state;
    let modelData = {}

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })
    let timeline = modelData.timeline
    let proofOfConcept = modelData.proofOfConcept
    return (
      <div className="columns is-mobile">
        <div className="column is-two-thirds container">
          <span className="title is-6"> About POC</span>
          <div className="has-text-left" key="1">
              The below proof of concepts (POCs) focused on determining whether this idea can be turned into a reality. They are meant to determine the feasibility of the idea or to verify that the idea will function as envisioned.
          </div>
          <br></br>
          <div className="panel">
            <div className="panel-heading">
              Notebooks
            </div>
            { proofOfConcept.map(this.renderProofOfConceptItem) }
          </div>
        </div>
        <div className="column is-one-third container">
        </div>
      </div>
    );
  }

  renderHero() {
    let { imageLink, data, modelId } = this.state;
    let modelData = {}

    let { heroColor } = this.props

    data.forEach(model => {
      if (model.name == modelId) {
        modelData = model
      }
    })

    return (
      <div className="hero" style={{backgroundColor: heroColor || 'rgba(130, 202, 157, 0.3)'}}>
        <div className="hero-body">
          <div className="tile is-ancestor">
            <div className="tile is-1 has-text-right" style={{overflow: 'hidden'}}>
              <FontAwesomeIcon className="is-size-1" icon={faShoppingCart} style={{margin: 'auto'}}/>
            </div>
            <div className="tile is-vertical">
              <p className="is-size-2">{modelData.title}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderTimelineItem(item, isBig) {
    let bigFonts = isBig || false;

    let iconMapping = {'report': faChartLine, 'prediction': faSearchPlus, 'update': faWrench};
    let icon = iconMapping[item.type];
    return (
      <div className="panel-block">
        <span className={bigFonts ? "panel-icon is-size-3" : "panel-icon is-size-5"}>
          <FontAwesomeIcon icon={icon} />
        </span>
        <div>
          <p className={bigFonts ? "is-size-5" : "is-size-6"}>{item.title}</p>
          <p className={bigFonts ? "is-size-6" : "is-size-7"}>
            { item.description ? (<span>{item.description} - </span>) : null }
            <Link to={item.link}>{item.linkText}</Link>
          </p>
        </div>
      </div>
    )
  }

  renderProofOfConceptItem(item) {
    let iconMapping = {'lab': faFlask};
    let icon = iconMapping[item.type];
    return (
      <div className="panel-block">
        <span className="panel-icon"> <FontAwesomeIcon icon={icon} /> </span>
        <Link to={item.link}> {item.title} </Link>
      </div>
    )

  }

  truncate(str, n, useWordBoundary) {
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + " ...";
  };

  renderDynamicGallery() {
    const { error, isLoading, data } = this.state;


    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-mobile is-multiline is-centered">
          <div className="column has-text-centered is-full">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    let model_list_div = []

    if (modelId == "") {
      if (Array.isArray(data)) {
        data.map(model => {
          // NOTE: On Amplify, the query string approach requres a trailing slash
          //        right before the query string `?`, otherwise the query params
          //        will be lost in redirects. See https://github.com/aws-amplify/amplify-console/issues/97#issuecomment-663103815
          let url = `models/${model.name.replace(" ", "").toLowerCase()}`
          model_list_div.push(
            <div className='quick-look-gallery'>
              <div className='quick-look'>
                <Link to={url}>
                    { model.name ? <h4 className="quick-look-title"> { model.name } </h4> : null }
                    { model.description ? (<h6 className="quick-look-subtitle" data-tooltip={this.truncate(model.description, 120, "useWordBoundary")}> { this.truncate(model.description, 60, "useWordBoundary") } </h6>) : null }
                </Link>
                <div className="has-text-right">
                    { url ? <Link to={url}>view model</Link> : "" }
                </div>
                { /* remove the image sizing parms (e.g =s220 by default)
                  https://developers.google.com/people/image-sizing
                <img
                  alt=""
                  src={model.hasThumbnail ? model.thumbnailLink.split("=")[0] : preset_icons[`dashboardIcon`]}
                  style={{maxHeight: "25vh"}}
                /> */}
                <QuickLook
                  chartType="model"
                  modelMetric="gsv"
                  data={model.gsv_model_comparison ? model.gsv_model_comparison.data : null}
                  X="date"
                  Y={["baseline", `${model.name.replace(" ", "").toLowerCase()}`]}
                  chartHeight={200}
                />
              </div>
            </div>
          )
        })
      }

      return (
            <div className="columns is-mobile is-multiline is-centered">
               {
                 model_list_div
               }
            </div>
        )
      }

    if (modelId != "") {
      // if there is modelIdFromUrl, render content instead
      return (
        <div className="content" style={{ marginBottom: '-60px' }}>
          <div className="container">
            {this.renderHero()}
            {this.renderRight()}
          </div>
        </div>
      );
    }
  }

  renderFromProps() {
    const { error, isLoading, data } = this.state;

    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="container">
          {this.renderHero()}
          {this.renderRight()}
          {/* {this.props.children} */}
        </div>
      </div>
    );
  }

  render() {
    return this.renderFromProps()
  }
}

export default ModelBrowser;
