import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import { navigate } from "gatsby";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MDEditor from '@uiw/react-md-editor';

// TODO: unmock & update according to Table Permissions
function TableOverviewPermission(props) {
  // mock first
  const { userList, permission } = props;

  return (
    <div>
      <p className='title is-6'>Permissions</p>
      <div className="level pl-4" style={{marginBottom: '0.1rem'}}>
        <div className="level-left">
          <div className="level-item">
            <span className="is-italic">Editor:</span>
          </div>
          <div className="level-item">
            <span className="tag is-primary is-light">Analysts</span>
          </div>
          <div className="level-item">
            <span className="tag is-primary is-light">Data Engineers</span>
          </div>
        </div>

      </div>
      <div className="level pl-4" style={{marginBottom: '1rem'}}>
        <div className="level-left">
          <div className="level-item">
            <span className="is-italic">Viewer:</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">Auditors</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">TRA</span>
          </div>
          <div className="level-item">
            <span className="tag is-warning is-light">BSI</span>
          </div>
        </div>

      </div>
    </div>
  )
}


class DatasetInfo extends React.Component {
  constructor(props) {
    super(props);
    // if `props.data` is given, use the given data instead of pulling it from API

    this.state = {
      data: props.data,
      needToFetch: (props.data == null),
      error: null,
      isLoading: (props.data == null),
      isEditing: props.isEditing
    };
  }

  componentDidMount() {
    const { needToFetch } = this.state;
    if (needToFetch) {
        this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data || this.props.date !== prevProps.date){
      // this.fetchData();
      this.setState({
        isEditing: this.props.isEditing
      })
    }
  }

  fetchData() {
    const callApiUrl = `lake/dataset/${this.props.data.name}`
    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        const loadedResult = result.data;
        if (loadedResult == null) throw Error('Invalid dataset info received.');
        this.setState({
          error: null,
          data: loadedResult,
          isLoading: false,
        });
      },
      (error) => this.setState({ error, isLoading: false }),
    );
  }

  jsonifyWithEscape(string) {
    var myJSONString = JSON.stringify(string);
    var myEscapedJSONString = myJSONString.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");
    return myEscapedJSONString
  }

  handleChange = value => {
    this.setState({
      datasetDescription: value
    });
  };

  async deleteDataset(datasetName) {
    var r = window.confirm(`Do you really want to delete dataset ${datasetName}? This cannot be undone!`);
    if (r == true) {
      this.setState({
        updatingStatus: true
      })
      const callApiUrl = `dataset/delete?name=${datasetName}`;
      await callApi(
        callApiUrl,
        (result) => {
          const loadedResult = result.event;
          if (loadedResult == null) throw Error('Performance is not succeeded.');
          if (loadedResult === []) {
            // no permission to data
            this.setState({
              error: null,
            });
          } else {
            this.setState({
              loadedResult: loadedResult
            })
          }
        (error) => this.setState({ error })}
      );
      if (this.state.loadedResult.includes("Successfully")) {
        this.setState({
          updatingStatus: false
        })
        window.alert(`Dataset ${datasetName} deleted!`)
        let currentUrl = window.location.href
        let destinationUrl = currentUrl.replace(`/${datasetName}`, "")
        // navigate(`${destinationUrl}`)
        location.reload()
      } else {
        window.alert(`Unauthorized`)
        this.setState({
          updatingStatus: false
        })
      }
    } else {
      window.alert(`Nothing changes!`)
    }
  }

  async editDataset(datasetName) {
    const { isEditing, datasetDescription } = this.state
    if (isEditing) {
      var r = window.confirm(`Update dataset ${datasetName} description?`);
      if (r == true) {
        this.setState({
          updatingStatus: true
        })

        let payload = {
          name: datasetName,
          description: datasetDescription,
        }

        const callApiUrl = `dataset/edit`;
        await callApi(
          callApiUrl,
          (result) => {
            const loadedResult = result.event;
            if (loadedResult == null) throw Error('Performance is not succeeded.');
            if (loadedResult === []) {
              // no permission to data
              this.setState({
                error: null,
              });
            } else {
              this.setState({
                loadedResult: loadedResult
              })
            }
          },
          (error) => this.setState({ error }),
          {
            method: 'POST',
            body: this.jsonifyWithEscape(payload),
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (this.state.loadedResult.includes("Successfully")) {
          this.setState({
            updatingStatus: false,
            isEditing: false,
          })
          window.alert(`Dataset ${datasetName} updated!`);
          location.reload()
        } else {
          window.alert(`Unauthorized`)
          this.setState({
            updatingStatus: false,
            isEditing: false
          })
        }
      } else {
        window.alert("Nothing changes!")
        this.setState({
          isEditing: false
        })
      }
    } else {
      this.setState({
        isEditing: true
      })
    }
  }

  checkIfCommonDataset() {
    let url = window.location.href
    if (url.includes("dataset/common")) {
      return 'True'
    } else {
      return ''
    }
  }


  render() {
    const { error, isLoading, data, isEditing } = this.state;

    let renderedData = this.props.data || data;

    if (error) {
      if (error.message.includes('404')) {
        return "Dataset info is not available."
      }
      return (
        error.message
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    let zones = []
    if (renderedData.zones) {
        Object.keys(renderedData.zones).map(zone => {
          zones.push(
            <li id={zone} style={{marginLeft: '25px'}}>{zone}</li>
          )
        })
    }

    const renderedDescription = renderedData.description ? renderedData.description : `Dataset: ${renderedData.name}`;

    return (
    <div>
      <div
        className="hero is-medium"
        style={{
          // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(' +              'https://png.pngtree.com/png-clipart/20190115/ourlarge/pngtree-technological-sense-current-e-edition-circuit-board-png-image_349548.jpg' + ')',
          backgroundImage: 'url(https://png.pngtree.com/png-clipart/20190115/ourlarge/pngtree-technological-sense-current-e-edition-circuit-board-png-image_349548.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div classname="hero-body">
          <p className='title'>
            {renderedData.name}
          </p>
        </div>
      </div>
      <br/>
      <div className="columns is-centered">
        <div className="column has-text-left is-10">
          <p className='title is-6'>Description</p>
          <div className="container">
            { isEditing ? (
            <div id="dataset_readme">
                <MDEditor
                value={renderedDescription}
                onChange={this.handleChange}
                />
            </div>
            ) : (
            <div id="dataset_readme">
                <MDEditor.Markdown source={renderedDescription} />
            </div>
            )}
            <br/>
            <div className='level'>
              <div className='level-left'></div>
              <div className='level-right'>
                <button
                  className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`}
                  disabled={this.checkIfCommonDataset()}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => this.editDataset(renderedData.name)}
                >
                  <FontAwesomeIcon icon={ isEditing ? faSave : faPencilAlt } />
                </button>
                <button
                  className={`button ${this.state.updatingStatus ? 'is-loading' : ""}`}
                  disabled={this.checkIfCommonDataset()}
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  onClick={() => this.deleteDataset(renderedData.name)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          </div>
          <hr/>
          <TableOverviewPermission />
        </div>
      </div>
    </div>
    );
  }
}

export default DatasetInfo;
