import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';


export default function GalleryGridLayout({
  data,
  CatalogItemRender, CatalogPageRender,
  filterValue, selectedItem, setSelectedItem,
  isCreating,
}) {

  const itemsPerPage = 6;

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(
    data.slice(itemOffset, itemOffset + itemsPerPage)
  );

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / itemsPerPage));
  }, [data, itemOffset, itemsPerPage]);

  if (selectedItem) {
    return (
      <div className='container'>
        <div className='has-text-right'>
          <button className='button' onClick={() => setSelectedItem(null)}>
            Back to Gallery
          </button>
        </div>
        <hr/>
        <CatalogPageRender {...selectedItem} />
      </div>
    )
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className='columns is-multiline'>
        {
          currentItems.map((e) => (
            <div className='column is-4' onClick={() => setSelectedItem(e)}>
              <CatalogItemRender {...e} filterValue={filterValue}/>
            </div>
          ))
        }
      </div>
      <nav className='pagination is-centered'>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="Previous"
          renderOnZeroPageCount={null}
          containerClassName='pagination-list'
          previousLinkClassName='pagination-previous'
          nextLinkClassName='pagination-next'
          pageLinkClassName='pagination-link'
          breakLinkClassName='pagination-ellipsis'
          disabledLinkClassName='is-disabled'
        />
      </nav>
    </>
  )
}