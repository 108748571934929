import React, { useState } from 'react';
import { useApiForComponent, callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { useQuery, useMutation } from 'react-query'


const operations = [
    "DROP",
    "ANONYMIZE",
]


function ColumnHeader(props) {
    const { title, currentlySelected, setColDropdown, addOpt } = props;
    return (
        <span>
            {title + " "}
            <span className={currentlySelected ? "dropdown is-active" : "dropdown"}>
                <div className="dropdown-trigger" onClick={() => currentlySelected ? setColDropdown(null) : setColDropdown(title)}>
                    <span className="tag">+</span>
                </div>
                <div className="dropdown-menu">
                    <div className="dropdown-content">
                        {
                            operations.map(
                                e => (<a className="dropdown-item" onClick={() => {addOpt(e, title); setColDropdown(null)}}>{e}</a>
                            ))
                        }
                    </div>
                </div>
            </span>
        </span>
    )
}


function TablePreviewInTransform(props) {
    const { table_id, dataset_id, zone_id, group_id, schema, addOpt, setSchemaState } = props;
    // const { isLoading, isError, data, error } = useApiForComponent(`lake/table/${table_id}?dataset=${dataset_id}&zone=${zone_id}&limit=10`)
    // const groupstring = group_id ? `&groups=users,${group_id}` : ""
    var groupstring = '';
    if (group_id) {
      if (group_id != 'common') groupstring = `&groups=users,${group_id}`;
    }
    const { isLoading, isError, data, error } = useQuery(
        [`lake/table/${table_id}?dataset=${dataset_id}&zone=${zone_id}&limit=10`, {}],
        async () => await callApiWithResult(`lake/table/${table_id.replace('.', '_')}?dataset=${dataset_id}&zone=${zone_id}${groupstring}&limit=10`)
    )
    const [colDropdown, setColDropdown] = useState(null);

    // return (<div>..suspended..</div>)

    if (isError) {
      if (error.message.includes('404')) return "Table preview is not available.";
      return error.message;
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    // let headerTitleDiv = []
    // schema.forEach((column) => {
    //   return (
    //     headerTitleDiv.push(<th>{column.name}</th>)
    //   )
    // })
    var usedSchema = schema;
    if (usedSchema == null && data.data) {
      usedSchema = Object.keys(data.data[0]);
      if (setSchemaState) setSchemaState(usedSchema);
    }

    if (usedSchema == null) return null;

    let headerTitleDiv = usedSchema.map(
        e => (
        <th>
            <ColumnHeader
                title={e.name}
                currentlySelected={e.name == colDropdown}
                setColDropdown={setColDropdown}
                addOpt={addOpt}
            />
        </th>
        )
    )

    console.log("MLTab data", data, isLoading, isError);

    if (data.data == null) return ""; // TODO: handle error

    let tableBodyDiv = []
    let tableBodyRow = []
    data.data.map(row => {
      tableBodyRow = []
      schema.forEach((column) => {
        tableBodyRow.push(<td>{row[column.name]}</td>)
      })
      tableBodyDiv.push(
        <tr>
          { tableBodyRow }
        </tr>
      )
    })

    return (
      <div className="table-container">
        <table className="table is-bordered is-fullwidth is-size-7">
          <thead>
            <tr>
              { headerTitleDiv }
            </tr>
          </thead>
          <tbody>
              { tableBodyDiv }
          </tbody>
        </table>
      </div>
    );
}

function TablePreviewPostTransform(props) {
  const { table_id, dataset_id, zone_id, group_id, schema, opList } = props;

  console.log(props);

  var groupstring = '';
  if (group_id) {
    if (group_id != 'common') groupstring = `users,${group_id}`;
  }

  const payload = {
    table_id, dataset_id, zone_id, columns: schema ? schema.map(e => e.name) : null, op_list: opList, groups: groupstring
  }
  const fetchOptions = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json' },
  }
  const { isLoading, isError, data, error } = useQuery(
    [`transform/preview`, fetchOptions],
    async () => await callApiWithResult(`transform/preview`, fetchOptions)
  )

  if (schema == null) return null;
  if (opList.length <= 0) return null;

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  console.log("Preview")

  // tempSchema + newSchema to preserve column order
  var tempSchema = [];
  data.data.forEach(e => Object.keys(e).forEach(c => (tempSchema.indexOf(c) === -1) ? tempSchema.push(c) : ""))
  var newSchema = [];
  schema.forEach(e => {
    if (tempSchema.indexOf(e.name) != -1) {
      newSchema.push(e.name);
    }
  })

  let headerTitleDiv = newSchema.map((column) => (<th>{column}</th>))

  let tableBodyDiv = []
  let tableBodyRow = []
  data.data.forEach(row => {
    // console.log("processing row", row)
    tableBodyRow = []
    newSchema.forEach((column) => {
      // console.log("filling row cell", column, row[column])
      tableBodyRow.push(<td>{row[column]}</td>)
    })
    tableBodyDiv.push(
      <tr>
        { tableBodyRow }
      </tr>
    )
  })

  return (
    <div className="table-container">
      <table className="table is-bordered is-fullwidth is-size-7">
        <thead>
          <tr>
            { headerTitleDiv }
          </tr>
        </thead>
        <tbody>
            { tableBodyDiv }
        </tbody>
      </table>
    </div>
  );

}


function OperationItem(props) {
    const { operation, delOpt } = props;

    const opColors = {
        'DROP': 'is-danger',
        'ANONYMIZE': 'is-link'
    }
    const opColor = opColors[operation.operation];

    return (
        <div className="">
            <div className={`notification ${opColor}`} style={{padding: "0.5rem"}}>
                <button className="delete" onClick={() => delOpt()}/>
                <div className="columns">
                    <div className="column is-5">
                        <span className="subtitle is-6">{operation.operation}</span>
                    </div>
                    <div className="column is-5">
                        <span className="is-italic">{operation.column}</span>
                    </div>
                </div>
            </div>
            {/* <br/> */}
        </div>
    )
}


function OperationList(props) {
    // props.opList
    const { opList, delOpt } = props;

    return (
        <div>
            <h4>Transformations</h4>
            <div className="">
                <ol>
                    {opList.map((e, i) => (
                        <li><OperationItem operation={e} delOpt={() => delOpt(i)}/></li>
                    ))}
                </ol>
                <div className="is-italic is-size-7">
                  {
                    opList.length > 0 ? "" : "Add transformations by clicking `+` on a column."
                  }
                </div>
                
            </div>
        </div>
    )
}


function TransformSaveDialog(props) {

  const [outputTable, setOutputTable] = useState("");
  const [pipeline, setPipeline] = useState("");

  const { onClickSave, isLoading } = props;

  const loadingIndicator = (
    <div className="columns is-centered">
      <div className="column has-text-centered is-10">
        <div className="iframe-holder" />
      </div>
    </div>
  );

  return (
      <div>
          <div className="field">
              <label className="">Target Table</label>
              <div className="control">
                  <input className="input" type="text" value={outputTable} onChange={e => setOutputTable(e.target.value)}></input>
              </div>
          </div>
          <div className="field">
              <label className="">Pipeline</label>
              <div className="control">
                  <input className="input" type="text" value={pipeline} onChange={e => setPipeline(e.target.value)}></input>
              </div>
          </div>
          <div>
              <a className="button" onClick={() => onClickSave(outputTable, pipeline)}>Save</a>
          </div>
          <div>
            { isLoading ? loadingIndicator : "" }
          </div>

      </div>
  )

}


export default function TableTransformWizard(props) {
    const { table_id, dataset_id, zone_id, group_id, schema, closeWindow, manualNavigate } = props;
    const { isLoading, isError, data, error, mutate } = useMutation(
      payload => {
        const fetchOptions = {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: { 'Content-Type': 'application/json' },
        }
        return callApiWithResult(`transform/save`, fetchOptions);
      }, {
        onSuccess: () => {
          console.log("MUTATE SUCCESS");
          closeWindow();
          setTimeout(() => {
            let url = `/dataset/users/${group_id}/${dataset_id}/`; // TODO: navigate to new table
            manualNavigate({
              activeUrl: url,
              refresh: true,
            });
          }
          , 500);
        }
      }
    )
    const [opList, setOpList] = useState([]);
    const [schemaState, setSchemaState] = useState(schema);
    /* 
        (tentative) opList - a list of operation objects, which generally looks like:
        {
            operation: "DROP",
            column: "column_name",
        }
        -- OR --
        {
            operation: "ANONYMIZE",
            column: "column_name",
            method: "random" || "pseudo",
        },
    */
    const addOpt = (operation, column) => {
        const newOpList = [ ...opList, { operation, column } ]
        setOpList(newOpList);
    }

    const delOpt = (optIndex) => {
        opList.splice(optIndex, 1);
        const newOpList = [ ...opList]
        setOpList(newOpList);
    }

    const onClickSave = (output_table_id, pipeline) => {
      console.log("CLICKSAVE", output_table_id, pipeline)
      if (output_table_id == "") return;

      var groupstring = '';
      if (group_id) {
        if (group_id != 'common') groupstring = `users,${group_id}`;
      }
      mutate(
        {
          table_id, dataset_id, zone_id, output_table_id, pipeline, groups: groupstring,
          columns: schemaState.map(e => e.name), op_list: opList,
        }
      )
      // closeWindow();
    }

    return (
        <div className="columns">
            <div className="column is-two-thirds">
                <TablePreviewInTransform
                    dataset_id={dataset_id}
                    table_id={table_id}
                    zone_id={zone_id}
                    group_id={group_id}
                    schema={schemaState}
                    addOpt={addOpt}
                />
                <hr/>
                <p className="is-size-4">Transform Preview</p>
                <TablePreviewPostTransform 
                    dataset_id={dataset_id}
                    table_id={table_id}
                    zone_id={zone_id}
                    group_id={group_id}
                    schema={schemaState}
                    opList={opList}
                />
            </div>
            <div className="column is-one-third">
                <div style={{marginBottom: "auto"}}>
                    <OperationList opList={opList} delOpt={delOpt}/>
                </div>
                <hr/>
                {/* <div className="" style={{marginTop: "auto"}}>
                  <button className="button" style={{width: "100%"}}>Save</button>
                </div> */}
                <TransformSaveDialog onClickSave={onClickSave} isLoading={isLoading}/>
            </div>

        </div>
    )
}