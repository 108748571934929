import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import useCollapse from 'react-collapsed';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterResults from 'react-filter-search';

import GalleryGridLayout from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseGallery/GalleryGridLayout';
import GalleryMenubarLayout from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseGallery/GalleryMenubarLayout';


function BaseItemRenderer(props) {
  // Most basic item renderer. Assume props have `name` and `description`.
  const { name, description } = props;
  return (
    <div className='box'>
      <p className='subtitle'>{name}</p>
      <p>{description}</p>
    </div>
  )
}


function BasePageRenderer(props) {
  // Most basic item renderer. Assume props have `name` and `description`.
  const { name, description } = props;
  return (
    <div className='container'>
      <p className='Title'>{name}</p>
      <hr/>
      <p>{description}</p>
    </div>
  )
}


function GalleryLoading(props) {
  return (
    <div className='container'>
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    </div>
  );
}


function GallerySearchBox(props) {
  const { galleryName, filterValue, setFilterValue } = props;
  const { sortOrder, setSortOrder, setIsCreating, canCreate } = props;
  const { sortFieldMapping, selectedSortField, setSelectedSortField } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <>
      <div className='level'>
        <div className='level-left'></div>
        <div className='level-right'>
          <div className='level-item'>
            <input
              className='input'
              type="text"
              value={filterValue}
              placeholder={`Search ${galleryName}`}
              onChange={(event) => setFilterValue(event.target.value)}
            />
          </div>
          <div className='level-item'>
            <button className='button' {...getToggleProps()}>
              {/* {isExpanded ? 'Collapse' : 'Expand'} */}
              <FontAwesomeIcon icon={faCog} />
            </button>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-half'></div>
        <div className='column is-half'>
          <section {...getCollapseProps()}>
            <div className='field is-horizontal has-addons has-addons-right'>
              <div className='field-label is-normal'>
                <label className='label'>Sort By</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control is-expanded'>
                    <div className='select is-fullwidth'>
                      <select onChange={(e) => setSelectedSortField(e.target.value)}>
                        {
                          Object.keys(sortFieldMapping).map(e => (
                            <option key={`sort-opts-${e}`}
                              selected={e == selectedSortField}>
                                {e}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                </div>
                <div className='field'>
                  <div className='control is-expanded'>
                    <div className='select is-fullwidth'>
                      <select onChange={(e) => setSortOrder(e.target.value)}>
                        <option selected={sortOrder == "Ascending"}>
                          Ascending
                        </option>
                        <option selected={sortOrder == "Descending"}>
                          Descending
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='has-text-right'>
              { canCreate ? (<button
                className='button'
                onClick={() => setIsCreating(true)}
              >
                Create new..
              </button>) : ""}
            </div>
            <hr/>
          </section>
        </div>
      </div>
    </>
  )
}


function getPropertySortFunction(propertyString, ascending) {
  /* Property string can refer to nested property in the form of a.b.c */

  return (a, b) => {
    let propertyNestList = propertyString.split(".");
    var aProperty = a;
    var bProperty = b;

    propertyNestList.forEach((key => {
      aProperty = aProperty[key];
      bProperty = bProperty[key];
    }))

    if (ascending) {
      return ( aProperty > bProperty ? 1 : -1)
    } else {
      return ( aProperty > bProperty ? -1 : 1)
    }
  }
}


export default function BaseGallery(props) {
  /*
    A reusable gallery for showing catalogue of items.

    `data` - A list of objects that serves as the underlying data.
    `query` - An alternative to data, which is an argument for react-query in
              the form of { key, method }
    `mainKey` - if `data` or result of `query` is an object rather than a
                list, this is the key used to access the list.
    `catalogItemRender` - a React Component function used to render
              the gallery children. Assume that all properties in data
              is available as props.
    `catalogPageRender` - a React Component function used to render a
              full page of the selected item. Assume that all properties in data
              is available as props.
    `catalogEmptyPageRender` - a React Component function to render the right
              section of `menubar` layout when no item is selected. Only applicable
              to `menubar` layout.
    `layout` -
    `sortFieldMapping` - an object { name: field } where `name` is the reflected
      name for sorting, and `field` is the actual field name in the data structure.
      field can have `.` to access nested properties.
    `identityKeyFunc` - A function that accepts an element of list,
              and returns a string. Used for URL navigation.
    `initialOpenNodes` - Only for `menubar` layout, this is a list of string that
              indicates what keys are active in the menubar at the initial render.
    `descending` - boolean as to whether to sort in descending order initially.
    `rootUrl` - a URL substring indicating the root of this gallery. Any URL string
              detected after the rootUrl is taken as the item ID to be selected,
              so that the gallery items can be navigated individually via their
              own link.
    `selectorFromSuffix` - a function that takes the part of url after the root
              url, and return the selectedItem to be selected. must take 2 arguments
              (childUrl, data) => {};
    `location` - gatsbt/reach-router's `location` object for navigation purposes.

      Additional notes:
        * there's a state available across items and pages, which is
          [sharedState, setSharedState] that can be used for communication
          between the item and the page
  */
  var {
    // data,
    name,
    query,
    mainKey,
    CatalogItemRender,
    CatalogPageRender,
    CatalogEmptyPageRender,
    CatalogCreateRender,
    layout,
    sortFieldMapping,
    identityKeyFunc,
    initialOpenNodes,
    descending,
    rootUrl,
    selectorFromSuffix,
    location,
  } = props;


  const [filterValue, setFilterValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSortField, setSelectedSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState((descending ? 'Descending' : 'Ascending'));
  const [isCreating, setIsCreating] = useState(false);
  const [sharedState, setSharedState] = useState(null);
  const { isLoading, isError, data, error } = useQuery(
    query.key, query.method
  )

  useEffect(() => {
    if (rootUrl && data) {
      let childUrl = location.pathname.split(rootUrl)[1];
      if (childUrl != null && selectorFromSuffix != null) {
        console.log('childUrl', childUrl);
        var preSelectedItem = selectorFromSuffix(childUrl, data[mainKey]);
        console.log("pre selected item", preSelectedItem);
        setSelectedItem(preSelectedItem.selectedItem);
        setSharedState(preSelectedItem.sharedState);
      }
    }
  }, [data])




  sortFieldMapping = sortFieldMapping || {};
  sortFieldMapping[null] = null;

  if (isLoading) return (<GalleryLoading />);

  if (isError) return (<div> ERROR: {JSON.stringify(error)} </div>)

  console.log("Gallery Data", data)

  var usedData = data;
  if (mainKey) { usedData = data[mainKey] }
  if (selectedSortField) {
    usedData.sort(
      getPropertySortFunction(
        sortFieldMapping[selectedSortField],
        sortOrder == "Ascending",
      )
    )
  }

  // filling in defaults for props
  CatalogItemRender = CatalogItemRender || BaseItemRenderer;
  CatalogPageRender = CatalogPageRender || BasePageRenderer;

  layout = layout || "grid";
  var LayoutRender = GalleryGridLayout;
  if (layout == "menubar") {
    LayoutRender = GalleryMenubarLayout
  }

  return (
    <div className={ layout == 'grid' ? 'container' : ''}>
      <GallerySearchBox
        galleryName={name}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        sortFieldMapping={sortFieldMapping}
        selectedSortField={selectedSortField}
        setSelectedSortField={setSelectedSortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setIsCreating={setIsCreating}
        canCreate={CatalogCreateRender != null}
      />
      <FilterResults
        value={filterValue}
        data={usedData}
        renderResults={(results) => {
          return (
            <LayoutRender
              data={results}
              filterValue={filterValue}
              CatalogItemRender={CatalogItemRender}
              CatalogPageRender={CatalogPageRender}
              catalogEmptyPageRender={CatalogEmptyPageRender}
              CatalogCreateRender={CatalogCreateRender}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              sharedState={sharedState}
              setSharedState={setSharedState}
              // identityKeyFunc={identityKeyFunc}
              initialOpenNodes={initialOpenNodes}
              sortOrder={sortOrder}
            />
          )
        }}
      />
    </div>
  )
}

export { getPropertySortFunction }
