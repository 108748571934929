import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import { Link } from 'gatsby';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import { faUser } from '@fortawesome/free-solid-svg-icons';


class UserGroups extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: {
                "user@tra.sg": {
                    "Email": "user@tra.sg",
                    "Groups": ["a_group"],
                }
            },
            // dataMock: { // TODO: change to use data once API is implemented
            //     users: [
            //         {
            //             "user": "ghosalya@tra.sg",
            //             "groups": ['TRA', 'Analysts'],
            //         },
            //         {
            //             "user": "eddie@tra.sg",
            //             "groups": ['TRA', 'Analyst'],
            //         },
            //         {
            //             "user": "mahmood@tra.sg",
            //             "groups": ['TRA'],
            //         },
            //         {
            //             "user": "bsi-analyst@tra.sg",
            //             "groups": ['Auditors'],
            //         },
            //     ]
            // },
            page: 0,
        }

        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        return 'pass';
    }

    getTitle() {
        if (this.props.title) {
          return (
            <Link to="/status/users"> {this.props.title} </Link>
          )
        } else {
          return "User Groups";
        }
    }

    getApiPath() {
        return "status/user/allgroups";
    }

    renderRow(e) {
        return (
            <tr>
                <td>{e['Email']}</td>
                <td>
                    {
                        e['Groups'].map((g) => (
                            <span className="tag is-primary is-light">{g}</span>
                        ))
                    }
                </td>
            </tr>
        )
    }

    renderMetric() {
        let data = this.state.data;

        return (
            <div>
                <table className='table'>
                    <thead>
                        <th>User</th>
                        <th>Groups</th>
                    </thead>
                    <tbody>
                        {
                            Object.values(data).map((e) => this.renderRow(e))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}


export default UserGroups;
