import React from 'react';
import ExperimentView from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/ExperimentView'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";
import NotificationBrowser from '@tra-sg/gatsby-theme-c360-portal/src/components/NotificationBrowser'

export default function ExperimentViewPage({}) {
  return (
    <PageWrapper
      activeTab="labs"
      metaTitle="Labs | c360"
    >
      <Location>
        {({ location }) => (
            <div style={{marginTop: '60px'}}>
                <NotificationBrowser location={location}/>
            </div>
        )}
      </Location>
    </PageWrapper>
  )
}


