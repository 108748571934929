import React, { useMemo, Component } from "react";
import ReactDOM from "react-dom";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Text,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  ComposedChart,
  Area,
  Line,
  Legend
} from "recharts";

export default class KPIGoalComponent extends Component {
  constructor(props) {
    super(props);
  }

  abbreviateNumber(number) {
    // ref https://stackoverflow.com/questions/9461621/format-a-number-as-2-5k-if-a-thousand-or-more-otherwise-900
    var SI_SYMBOL = ["", "K", "M", "B"];
    // what tier? (determines SI symbol)
    var tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  }

  render() {
    let selectedSolution = this.props.selectedSolution
    let data = []
    let color = {
      "p2b_actual": "yellow",
      "p2b_projected": "lightyellow",
      "personalize_projected": "#ffebed",
      "salesforecast_projected": "LightBlue"
    } // TODO: get color from pallete, actual vs projected can be gradient from each other

    data = [
        {month: "Jan 2022", baseline_actual: 653400, baseline_projected:null, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Feb 2022", baseline_actual: 1357800, baseline_projected:null, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Mar 2022", baseline_actual: 2049000, baseline_projected:null, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Apr 2022", baseline_actual: 2858600, baseline_projected:null, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "May 2022", baseline_actual: 3310320, baseline_projected:3310320, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Jun 2022", baseline_actual: null, baseline_projected:3852384, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: 0},
        {month: "Jul 2022", baseline_actual: null, baseline_projected:4502860, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Aug 2022", baseline_actual: null, baseline_projected:5283432, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Sep 2022", baseline_actual: null, baseline_projected:6220119, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Oct 2022", baseline_actual:null, baseline_projected:7344143, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Nov 2022", baseline_actual:null, baseline_projected:8692972, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null},
        {month: "Dec 2022", baseline_actual:null, baseline_projected:10311566, p2b_actual: null, p2b_projected: null, personalize_actual: null, personalize_projected: null, salesforecast_projected: null}
      ];

    let areaDiv = []
    if ((selectedSolution || [].length > 0)) {
      selectedSolution.forEach(solution => {
        areaDiv.push(
          <Area type="monotone" stackId="1" dataKey={`${solution}_actual`} fill={`${color[`${solution}_actual`]}`} stroke="#8884d8" />
        )
        areaDiv.push(
          <Area type="monotone" stackId="2" dataKey={`${solution}_projected`} fill={`${color[`${solution}_projected`]}`} strokeDasharray="3 3"/>
        )
      })
    }

    return (
      <div>
        <ResponsiveContainer height={300} width="100%">
          <ComposedChart height={100} data={data}>
            <XAxis dataKey="month" tickFormatter={tick => {
              return this.abbreviateNumber(tick);
            }}/>
            <YAxis domain={[0, 12000000]} tickFormatter={tick => {
              return this.abbreviateNumber(tick);
            }}/>
            <Tooltip formatter={(value) => new Intl.NumberFormat('en').format(value)} />
            <ReferenceLine y={9000000} label={{ value: 'Target', fill: 'black', fontSize: 12, position: 'top'}} stroke="black" />
            <Area type="monotone" dataKey="baseline_actual" stackId="1" fill="green" stroke="#8884d8" isAnimationActive={true} animationDuration={5000}/>
            <Area type="monotone" dataKey="baseline_projected" stackId="2" fill="lightgreen" strokeDasharray="3 3" isAnimationActive={true} animationDuration={5000}/>
            { areaDiv }
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  };
}
