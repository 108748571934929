import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import MenuBar from '@tra-sg/gatsby-theme-c360-portal/src/components/MenuBar';
import HomePageMainContent from '@tra-sg/gatsby-theme-c360-portal/src/components/HomePageMainContent';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class HomePageTemplate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { metaTitle, metaDescription } = this.props

    return (
      <div>
        <Helmet>
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <section className="section section--gradient">
            <div>
              <div className="columns">
                <div className="column is-hidden-touch menubar" id="menubar">
                  <MenuBar activeTab='home-page'/>
                </div>
                <div className="home-main column is-full-mobile is-full-tablet is-full-touch is-full-desktop is-centered" style={{marginTop: "-15px"}}>
                  <HomePageMainContent />
                </div>
              </div>
          </div>
        </section>
      </div>
    )
  }
}
