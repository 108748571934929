import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import { description } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import CreateModelExperiment from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/CreateModelExperiment';
import { useQuery, useMutation } from 'react-query';
import { useApiForComponent, callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


function ModelExpListItem(props) {
  const { name, type, description, data } = props;
  const [showResult, setShowResult] = useState(false);

  function truncate_desc(desc) {
    const desc_length = 90;
    if (desc == null) return null;
    if (desc.length <= desc_length) {
      return desc;
    } else {
      return desc.substring(0, desc_length-3) + "...";
    }
  }

  return (
    <div className="column is-one-third">
      <div className="card" onClick={() => setShowResult(true)}>
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-32x32">
                <FontAwesomeIcon icon={faBrain} style={{width: '100%', height: '100%'}}/>
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-5">{name}</p>
              <p className="subtitle is-7">{type}</p>
            </div>
          </div>
          <div className="content">
            {truncate_desc(description)}
          </div>
        </div>
      </div>
      <div className={showResult ? "modal is-active" : "modal"}>
        <div className="modal-background" onClick={() => setShowResult(false)}></div>
        <div className="modal-card" style={{width: "1400px", margin: "15px", "padding-top": "30px", "padding-top": "30px"}}>
          <div className="modal-card-body">
            <CreateModelExperiment resultOnly status={"SUCCESS"} model_data={data}/>
          </div>
        </div>
      </div>
    </div>
  )
}



export default function LabModelExperiment(props) {

  const { isLoading, isError, data, error } = useQuery(
    [`models`, {}],
    async () => await callApiWithResult(`models`)
  )

  function group_model_experiments(data) {
    var grouped = {};

    data.models.forEach(m => {
      var key = m.name.split('/')[0];

      // TODO: find a different, more solid way of differentiating between
      //       prod and experiment models.
      if (m.name == key) return;

      if (key in grouped) {
        grouped[key].model_list.push(m)
      } else {
        grouped[key] = {
          name: key,
          type: m.type,
          description: m.description,
          model_list: [m],
        }
      }
    })

    return grouped;
  }

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  const dataGrouped = group_model_experiments(data);

  return (
    <div className="container">
      <p className="title is-4">Machine Learning Experiments</p>
      <div className="level">
        <div className="level-left"></div>
        <div className="level-right">
          <div className="level-item">
            <a className="button" onClick={props.handleCreate}>New Model</a>
          </div>
        </div>
      </div>
      <div className="columns is-multiline">
        { Object.values(dataGrouped).map((e) => (<ModelExpListItem name={e.name} type={e.type} description={e.description} data={e}/>))}
      </div>
      <div>
        <a className='button' href="/labs/experiment">See all</a>
      </div>
    </div>
  )
}
