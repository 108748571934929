import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import SearchBox from '@tra-sg/gatsby-theme-c360-portal/src/components/SearchBox';
import c360Logo from '@tra-sg/gatsby-theme-c360-portal/src/icons/c360-logo-00.svg';
import c360LogoNoText from '@tra-sg/gatsby-theme-c360-portal/src/icons/c360-no-text-00.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { faFunnelDollar } from '@fortawesome/free-solid-svg-icons';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import c360TenantLogo from '@tra-sg/gatsby-theme-c360-portal/src/icons/tenant-logo-00.svg';
import NavBarBell from '@tra-sg/gatsby-theme-c360-portal/src/components/NotificationBrowser/NavBarBell';
import { logoutOfApp } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';

async function focusSearch() {
  await global.toggleNavbar();
  const searchBox = document.getElementById('SearchBox');
  searchBox.focus();
}

// Note: This getElementById-centric implementation doesn't play very well
//       with React patterns in general. We can instead use React Hooks as
//       explained here: https://usehooks.com/useOnClickOutside/
// TODO: change toggle/close functions to use react hooks
function toggleDashboard() {
  let dropdown = document.getElementById('dashboard-dropdown');
  if (dropdown == null) return;

  if (document.getElementById('dashboard-dropdown').classList.contains('is-active')) {
    document.getElementById('dashboard-dropdown').classList.remove('is-active');
  } else {
    document.getElementById('dashboard-dropdown').classList.add('is-active');
  }
}

function closeDropdownsIfAnyClick(ev) {
  const target = ev.target || ev.srcElement;
  if (target) {
    if (target.parentNode) {
      if (target.parentNode.id !== 'nav-dropdown' && target.parentNode.id !== 'dashboard-dropdown' && target.id !== 'dashboard-dropdown') {
        let dropdown = document.getElementById('dashboard-dropdown')
        if (dropdown != null) dropdown.classList.remove('is-active');
      }

      if (target.id !== 'SearchBox' && target.id !== 'buttonNav' && target.parentNode.id !== 'nav-dropdown') {
        if (document.getElementById('buttonNav').classList.contains('is-active')) {
          global.toggleNavbar();
        }
      }
    }
  }

  return true;
}

if (typeof document !== 'undefined') {
  document.querySelector('body').addEventListener('click', closeDropdownsIfAnyClick);
}


const NavBar = ({ toggleNavbar, isActive, authorized }) => (
  // <StaticQuery
  //   query={graphql`
  //           query SearchIndexQuery2 {
  //               siteSearchIndex {
  //                   index
  //               }
  //           }
  //       `}
  //   render={(data) =>
      (
      <nav className="navbar is-fixed-top" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img alt="" src={c360Logo} width="150" height="auto" className="is-hidden-mobile"/>
            <img alt="" src={c360LogoNoText} width="80" height="auto" className="is-hidden-tablet is-hidden-desktop"/>
            <img alt="" src={c360TenantLogo} width="140" height="auto" />
          </Link>
          <button
            className={`button navbar-burger ${isActive ? 'is-active' : ''}`}
            data-target="navMenu"
            onClick={focusSearch}
            id="buttonNav"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id="navMenu">
          <div className="navbar-start">
            {/* { authorized ? <SearchBox searchIndex={data.siteSearchIndex.index} navBurger={isActive}/> : null } */}
          </div>
          <div className="navbar-end">
            <div className="navbar-item has-dropdown" style={{ display: `${isActive ? 'block' : 'none'}` }} onClick={toggleDashboard} id="dashboard-dropdown">
              <div className="navbar-link">
                Menu
              </div>
              <div className="navbar-dropdown" id="nav-dropdown">
                <Link className="navbar-item" to="/" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faHome} /> Home
                </Link>
                <Link className="navbar-item" to="/campaign" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faFlag} /> Campaign
                </Link>
                <Link className="navbar-item" to="/customer" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faUser} /> Customer
                </Link>
                <Link className="navbar-item" to="/sales" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faFunnelDollar} /> Sales
                </Link>
                <Link className="navbar-item" to="/survey" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faTabletAlt} /> Surveys
                </Link>
                <Link className="navbar-item" to="/labs" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faFlask} /> Labs
                </Link>
                <Link className="navbar-item" to="/dataset" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faDatabase} /> Datasets
                </Link>
                <Link className="navbar-item" to="/models" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faChartArea} /> Models
                </Link>
                <Link className="navbar-item" to="/status/overview" onClick={toggleNavbar} onMouseDown={(event) => event.preventDefault()}>
                  <FontAwesomeIcon icon={faHeartbeat} /> System Status
                </Link>
              </div>
            </div>
            <NavBarBell />
            &nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;
            { authorized ? <a className="button" onClick={async () => await logoutOfApp() } id="logout-button"> Log Out </a> : null }
          </div>
        </div>
      </nav>
    )
  // }
  // />
);

export default NavBar;
