import React, { useState } from 'react';
import BaseGallery, { getPropertySortFunction } from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseGallery';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import ModelBrowser from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain } from '@fortawesome/free-solid-svg-icons';
import KPIForecastDemo from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/KPIForecastDemo'
import KPIForecastResult from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/KPIForecastResult'
import ModelMetrics from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/ModelMetrics';
import IframeComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/IframeComponent';


function ModelItem({ name, title, description}) {

  function truncate_desc(desc) {
    const desc_length = 70;
    if (desc == null) return null;
    if (desc.length <= desc_length) {
      return desc;
    } else {
      return desc.substring(0, desc_length-3) + "...";
    }
  }

  var type = "";
  var shortTitle = null;
  if ( (title || name).includes("/") ) {
    type = (title || name).split("/")[0];
    shortTitle = (title || name).replace(type + "/", "");
  }

  return (
    <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-32x32">
                <FontAwesomeIcon icon={faBrain} style={{width: '100%', height: '100%'}}/>
              </figure>
            </div>
            <div className="media-content">
              <p className="title is-6">{shortTitle || title || name}</p>
              <p className="subtitle is-7">{type ? `Experiment: ${type}` : ""}</p>
            </div>
          </div>
          <div className="content">
            {truncate_desc(description)}
          </div>
        </div>
      </div>
  )
}


function ModelPage({ name, item, description}) {
  return (
    <div>
      <ModelBrowser
        modelId={name}
        heroColor="rgba(0, 60, 159, 0.3)"
        latestPerformanceConfig={getModelLatestPerformanceConfig(name)}
        description={description}
      >
        {getModelPageSpecialComponent(name)}
      </ModelBrowser>

    </div>
  )
}

function getModelLatestPerformanceConfig(name) {
  if (name == "kpiforecast") {
    return {
      chartType: "bar",
      X: "kpi",
      Y: ["current", "projected"],
      metric: "kpi_to_target"
    }
  }
  if (name == "p2b") {
    return {
      chartType: "pie",
      X: "type",
      Y: ["count"],
      metric: "customer_purchase"
    }
  }

  return {}
}

function getModelPageSpecialComponent(name) {
  if (name == "kpiforecast") {
    return (
      [
        (<div modelTab="overview">
          <KPIForecastResult />
        </div>),
        (<div modelTab="performance">
          <div className="row is-full">
            <div className="subtitle">Recent Performance (Jan 2021)</div>
            <div className="columns is-multiline">
              <div className="column is-one-third">
                <ModelMetrics modelId="kpiforecast" chartType="line_w_band" xValue="day" yValue={["pred_band", "actual"]} metric="sales_forecast" colorWheel={["#dddddd","#00b159","#f37735",]} disableColorOffset="true"/>
              </div>
              <div className="column is-one-third">
                <ModelMetrics modelId="kpiforecast" chartType="line_w_band" xValue="day" yValue={["pred_band", "actual"]} metric="customergrowth_forecast" colorWheel={["#dddddd","#00b159","#f37735",]} disableColorOffset="true"/>
              </div>
              <div className="column is-one-third">
                <ModelMetrics modelId="kpiforecast" chartType="line_w_band" xValue="day" yValue={["pred_band", "actual"]} metric="lifetimevalue_forecast" colorWheel={["#dddddd","#00b159","#f37735",]} disableColorOffset="true"/>
              </div>
              <div className="column is-full">
                <KPIForecastDemo />
              </div>
            </div>
          </div>
        </div>)
      ]
    )
  }

  if (name == "p2b") {
    return (
      [
        (<div modelTab="performance">
          <div className="row is-full">
            <div className="subtitle">Recent Performance (Jan 2021)</div>
            <div className="columns">
              <div className="column is-one-third has-text-centered">
                <img src={"https://www.jisc.ac.uk/sites/default/files/styles/710px-wide/public/illustrated-people.jpg"}>
                </img>
                <p className="subtitle">
                  <strong><ModelMetrics modelId="p2b" metric="identified_customer"/></strong> users identified
                </p>
              </div>
              <div className="column is-one-third has-text-centered">
                <ModelMetrics modelId="p2b" chartType="pie" xValue="type" yValue={["count"]} metric="customer_purchase"/>
              </div>
              <div className="column is-one-third">
                <ModelMetrics modelId="p2b" chartType="bar" xValue="type" yValue={["sales"]} metric="p2b_performance"/>
              </div>
            </div>
          </div>
          <div className="row is-full">
            <IframeComponent
              tenant="acme"
              stage="prod"
              dashboard_slug="propensity-to-buy"
              dashboard_url="https://datastudio.google.com/embed/reporting/70376bdd-43ce-454e-b26b-74901e0989b0"
            />
              </div>
        </div>)
      ]
    )
  }

  if ( name == "salesforecast") {
    return [
      (<div modelTab="overview">
        <IframeComponent
          tenant="acme"
          stage="prod"
          dashboard_slug="propensity-to-buy"
          dashboard_url="https://datastudio.google.com/embed/reporting/c1da8686-a162-4cb1-a9ec-7d45ff675f63"
          style={{height: '624px'}}
        />
      </div>),
      (<div modelTab="performance">
        <p className="subtitle">Forecast by Algorithm</p>
        <SalesForecastFeatureBreakdown name="Algorithms"
          featuresMape={{"Prophet": 21.2, "ARIMA": 49.8}}
        />
        <p className="subtitle">Forecast by Feature Set</p>
        <SalesForecastFeatureBreakdown name="Feature Set (with Best Algorithm)"
          featuresMape={{
            "Baseline": 21.2, "Grouped Forecast": 18.3, "Holiday": 16.7, "Grouped Forecast + Holiday": 7.4
          }}
        />
      </div>),
    ]
  }


  return "";
}


export default function ModelGallery(props) {
  return (
    <BaseGallery
      name="model"
      query={{
        key: [`models`, {}],
        method: async () => {
          let fullModelData = await callApiWithResult(`models`);
          let deployedModels = fullModelData.models.filter((m) => {
            return !m.name.includes("/");
            // The convention is that models from experiments are named
            // `experiment_name/model_name`, while deployed models shouldn't have
            // a slash.
          })
          return {
            models: deployedModels,
          }
        }
      }}
      mainKey={"models"}
      layout="grid"
      CatalogItemRender={ModelItem}
      CatalogPageRender={ModelPage}
      // sortFieldMapping={{
      //   "name": "name",
      // }}
      // style={{maxWidth: "80vw"}}
      // identityKeyFunc={(e) => ([...e.groups, e.datapackage.name]).join('/')}
    />
  )
}
