import React, { Component, PureComponent } from 'react';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import { faArrowUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';


export default function SourceDataFeed() {
    let data = [
        {
            title: (<span>Received data for <u>bsi_shared</u></span>),
            description: (<code>s3://acme-bsi-c360-upstream/bsi_shared/</code>),
            datetime: 'June 2, 2022 11:34',
            icon: faCalendarPlus
        },
        {
            title: (<span>Received data for <u>invoice</u></span>),
            description: (<code>s3://acme-bsi-c360-upstream/invoice/</code>),
            datetime: 'June 1, 2022 17:46',
            icon: faCalendarPlus
        },
    ]

    return (
        <Feed heading="Source Data" items={data} />
    )
}
