import React from 'react';
import DatalakeBrowser from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser'
import DatasetGallery from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetGallery'
import PageWrapper from '@tra-sg/gatsby-theme-c360-portal/src/components/PageWrapper';
import { Location } from "@reach/router";

export default function LabPageGallery({}) {
  return (
    <PageWrapper
      activeTab="datalake-page"
      metaTitle="Datasets | c360"
    >
      <Location>
        {({location}) => <DatasetGallery location={location} />}
      </Location>
    </PageWrapper>
  )
}
