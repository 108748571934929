/*
    TODO: resolve with <TablePreview /> component under DatalakeBrowser
*/
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default function LabTablePreview(props) {
  const { table_id, dataset_id, zone_id, group_id, setTableColumns } = props;
  console.log("LabTablePreview props", props)
  const table_id_underscore = table_id.replace(/\./g,'_')
  var groupstring = '';
  if (group_id) {
    if (group_id != 'common') groupstring = `&groups=${group_id}`;
  }
  const { isLoading, isError, data, error } = useQuery(
      [`lake/table/${table_id_underscore}?dataset=${dataset_id}&zone=${zone_id}&limit=10`, {}],
      async () => await callApiWithResult(`lake/table/${table_id_underscore}?dataset=${dataset_id}&zone=${zone_id}${groupstring}&limit=10`)
  )

  useEffect(() => {
    if (!isLoading) {
      if (data.data) {
        setTableColumns(Object.keys(data.data[0]));
      }
    }
  }, [isLoading])

  if (isError) {
    if (error.message.includes('404')) return "Table preview is not available.";
    return error.message;
  }

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  var usedSchema = null;
  if (usedSchema == null && data.data) {
    usedSchema = Object.keys(data.data[0]);
    // if (setSchemaState) setSchemaState(usedSchema);
  }
  console.log("ML USEDSCHEMA", usedSchema);

  if (usedSchema == null) return null;

  let headerTitleDiv = []
  usedSchema.forEach((column) => {
    return (
      headerTitleDiv.push(<th>{column}</th>)
    )
  })

  console.log("MLTab data", data, isLoading, isError);
  console.log("MLTab data breakdown", data, data.map);

  let tableBodyDiv = []
  let tableBodyRow = []
  data.data.map(row => {
    tableBodyRow = []
    usedSchema.forEach((column) => {
      tableBodyRow.push(<td>{row[column]}</td>)
    })
    tableBodyDiv.push(
      <tr>
        { tableBodyRow }
      </tr>
    )
  })

  return (
    <table className="table is-bordered is-fullwidth is-size-7">
      <thead>
        <tr>
          { headerTitleDiv }
        </tr>
      </thead>
      <tbody>
          { tableBodyDiv }
      </tbody>
    </table>
  );
}
